import React, { useState } from 'react';
import Navbar from './navbar';
import Footer from './footer';

export default function Layout({ children, initialData }) {
    const [showBanner, setShowBanner] = useState(true);
  
    return (
      <div className="min-h-screen flex flex-col">
        <Navbar initialData={initialData} showBanner={showBanner} setShowBanner={setShowBanner} />
        <main className={`flex-grow ${showBanner ? 'pt-[132px] md:pt-[140px]' : 'pt-[84px] md:pt-[88px]'}`}>
          {children}
        </main>
        <Footer initialData={initialData} />
      </div>
    );
}