import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Modal from './footer_modal';

export default function Footer({ initialData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', content: '' });

  const openModal = (title, content) => {
    setModalContent({ title, content });
    setIsModalOpen(true);
  };

  const logoPath = initialData?.data?.storage_url 
  ? `${initialData.data.storage_url}frontend/images/logos/wymbee.png`
  : '/static/frontend/images/logos/wymbee.png';

  const footerLinks = {
    company: [
      { label: 'Get Help', path: '/help' },
      { label: 'Pricing', path: '/pricing' },
      { label: 'Referral', path: '/referral' },
      { label: 'Demo', path: '/demo' },
    ],
    legal: [
      { label: 'Privacy Policy', path: '/privacy' },
      { label: 'Terms of Use', path: '/terms' },
    ],
    solutions: [
      {
        label: 'Deadstock Re-Distribution',
        onClick: () => openModal('Deadstock Re-Distribution', 
          'Deadstock re-distribution refers to the process of redistributing unsold or surplus inventory from businesses to other entities that can utilize it effectively. This reduces waste, cuts costs, and increases supply chain efficiency. With WymBee, we simplify this process for businesses, connecting them with optimal partners.'
        ),
      },
      {
        label: 'Social Commerce',
        onClick: () => openModal('Social Commerce',
          'Social commerce integrates social media with online shopping, enabling businesses to sell directly to consumers through social platforms. With WymBee, you can seamlessly manage your product catalog, engage with customers, and boost conversions by utilizing tools tailored for your social commerce strategy.'
        ),
      },
    ],
  };

  const socialLinks = [
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/wymbee/',
      icon: 'M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z'
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/getwymbee/',
      icon: 'M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z'
    },
    {
      name: 'Twitter',
      url: 'https://x.com/WymBee',
      icon: 'M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z'
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/getwymbee/',
      icon: 'M9 8H7v4h2v12h5V12h3.642L18 8h-4V6.4C14 5.837 14.224 5 15.632 5H18V0h-3.867C10.335 0 9 1.954 9 5.096V8z'
    }
  ];

  const openWhatsApp = () => {
    const url = "intent://send/?phone=254105279316#Intent;scheme=whatsapp;end";
    window.location.href = url;
  };

  return (
    <footer className="bg-gray-900 text-white">
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalContent.title}
        content={modalContent.content}
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-8">
        {/* Top Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-8 mb-12">
          {/* Brand Section */}
          <div className="lg:col-span-4">
            <Link to="/" className="block mb-6">
              <img 
                src={logoPath}
                alt="WymBee Logo" 
                className="h-8" 
              />
            </Link>
            <p className="text-gray-400 mb-8">
              Making commerce simpler and inventory smarter for businesses
            </p>
            <div className="flex flex-wrap gap-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="bg-white/10 hover:bg-white/15 px-6 py-3 rounded-lg flex items-center gap-2"
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.app.wymbee', '_blank')}
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M17.9,5.1L5.1,0.5C4.7,0.3,4.3,0.3,3.9,0.4L13.3,12L17.9,5.1z" />
                  <path d="M3.1,1C2.5,1.5,2,2.2,2,3v18c0,0.8,0.5,1.5,1.1,2l9.3-11.4L3.1,1z" />
                  <path d="M19.3,6L14.4,12l4.9,6c0.6-0.4,1-1.1,1-1.9V7.9C20.3,7.1,19.9,6.4,19.3,6z" />
                  <path d="M5.1,23.5c0.4,0.2,0.8,0.2,1.2,0.1l12.8-4.6l-4.6-6.9L5.1,23.5z" />
                </svg>
                <span>Get App</span>
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={openWhatsApp}
                className="bg-[#25D366] hover:bg-[#20bf5b] px-6 py-3 rounded-lg flex items-center gap-2"
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347z"/>
                </svg>
                <span>Chat</span>
              </motion.button>
            </div>
          </div>

          {/* Links Sections */}
          <div className="lg:col-span-8 grid grid-cols-1 sm:grid-cols-3 gap-8">
            {Object.entries(footerLinks).map(([category, links]) => (
              <div key={category}>
                <h3 className="text-white font-semibold mb-4 capitalize">{category}</h3>
                <ul className="space-y-3">
                  {links.map((link, index) => (
                    <li key={index}>
                      {link.path ? (
                        <Link
                          to={link.path}
                          className="text-gray-400 hover:text-white transition-colors duration-200"
                        >
                          {link.label}
                        </Link>
                      ) : (
                        <button
                          onClick={link.onClick}
                          className="text-gray-400 hover:text-white transition-colors duration-200 text-left"
                        >
                          {link.label}
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-800 pt-8">
          <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
            <p className="text-gray-400 text-sm text-center sm:text-left">
              © {new Date().getFullYear()} WymBee Technologies Limited. All rights reserved.
            </p>
            <div className="flex gap-6">
              {socialLinks.map((social) => (
                <motion.a
                  key={social.name}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors duration-200"
                  whileHover={{ scale: 1.1 }}
                  aria-label={social.name}
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                    <path d={social.icon} />
                  </svg>
                </motion.a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}