import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Navbar({ initialData }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuOpen]);

  const logoPath = initialData?.data?.storage_url 
    ? `${initialData.data.storage_url}frontend/images/logos/wymbee_dark.png`
    : '/static/frontend/images/logos/wymbee_dark.png';

  const navLinks = [
    { path: '/', label: 'Home' },
    { path: '/help', label: 'Get Help' },
    {
      path: '#',
      label: 'Use Cases',
      dropdownItems: [
        {
          title: 'Marketing Campaigns',
          description: 'Create and manage targeted WhatsApp marketing campaigns',
          path: '#',
          icon: '📱'
        },
        {
          title: 'Automate Conversations',
          description: 'Set up intelligent chatbots to handle customer queries 24/7',
          path: '#',
          icon: '🤖'
        },
        {
          title: 'WhatsApp Checkout',
          description: 'Enable seamless purchases directly through WhatsApp',
          path: '#',
          icon: '🛒'
        },
        {
          title: 'Catalog Management',
          description: 'Organize and showcase your products effectively',
          path: '#',
          icon: '📊'
        }
      ]
    },
    { path: '/pricing', label: 'Pricing' },
    { path: 'https://www.wymbee.com/blog/', label: 'Blog' },
  ];

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-200 ${
      isScrolled 
        ? 'bg-white/80 backdrop-blur-lg shadow-sm' 
        : 'bg-white'
    }`}>
      <nav className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img 
              src={logoPath}
              alt="WymBee Logo" 
              className="h-8 w-auto" 
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map((link) => (
              <div key={link.path} className="relative group">
                {link.dropdownItems ? (
                  <>
                    <button
                      className="text-sm font-medium text-gray-600 hover:text-gray-900 
                                transition-colors duration-200 py-2 group flex items-center"
                    >
                      {link.label}
                      <svg
                        className="ml-1 w-4 h-4 transition-transform duration-200 group-hover:rotate-180"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div className="absolute top-full left-1/2 -translate-x-1/2 pt-2 opacity-0 invisible 
                                  group-hover:opacity-100 group-hover:visible transition-all duration-200">
                      <div className="w-80 bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden">
                        <div className="p-2">
                          {link.dropdownItems.map((item) => (
                            <Link
                              key={item.title}
                              to={item.path}
                              className="flex items-start gap-3 p-3 hover:bg-gray-50 rounded-lg 
                                       transition-colors duration-200"
                            >
                              <span className="text-xl flex-shrink-0 mt-0.5">{item.icon}</span>
                              <div>
                                <h3 className="text-sm font-medium text-gray-900">{item.title}</h3>
                                <p className="text-xs text-gray-600 mt-0.5">{item.description}</p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <Link
                    to={link.path}
                    className={`text-sm font-medium transition-colors duration-200 ${
                      location.pathname === link.path 
                        ? 'text-primary' 
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                  >
                    {link.label}
                  </Link>
                )}
              </div>
            ))}
            <Link
              to="/demo"
              className="bg-primary text-white px-4 py-2 rounded-lg text-sm font-medium
                        hover:bg-primary-hover transition-colors duration-200"
            >
              Request Demo
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button
            type="button"
            className="md:hidden p-2 rounded-lg text-gray-600 hover:text-gray-900 
                     hover:bg-gray-100 focus:outline-none focus:ring-2 
                     focus:ring-inset focus:ring-primary"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="sr-only">Open menu</span>
            <div className="w-6 h-6 flex flex-col justify-center items-center">
              <span 
                className={`w-5 h-0.5 bg-current transition-transform duration-200
                  ${isMenuOpen ? 'rotate-45 translate-y-0.5' : '-translate-y-1'}`} 
              />
              <span 
                className={`w-5 h-0.5 bg-current transition-transform duration-200
                  ${isMenuOpen ? '-rotate-45' : 'translate-y-1'}`} 
              />
            </div>
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden fixed inset-0 top-16 bg-white z-50 overflow-y-auto">
            <div className="pt-2 pb-4 px-4">
              {navLinks.map((link) => (
                <div key={link.path} className="py-2">
                  {link.dropdownItems ? (
                    <div className="space-y-2">
                      <div className="text-sm font-medium text-gray-900">{link.label}</div>
                      <div className="pl-4 space-y-2">
                        {link.dropdownItems.map((item) => (
                          <Link
                            key={item.title}
                            to={item.path}
                            className="flex items-start gap-3 p-2 hover:bg-gray-50 rounded-lg"
                          >
                            <span className="text-xl flex-shrink-0 mt-0.5">{item.icon}</span>
                            <div>
                              <h3 className="text-sm font-medium text-gray-900">{item.title}</h3>
                              <p className="text-xs text-gray-600 mt-0.5">{item.description}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Link
                      to={link.path}
                      className={`block text-base font-medium ${
                        location.pathname === link.path
                          ? 'text-primary'
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      {link.label}
                    </Link>
                  )}
                </div>
              ))}
              <Link
                to="/demo"
                className="block w-full text-center bg-primary text-white px-4 py-2 
                          rounded-lg text-base font-medium hover:bg-primary-hover 
                          transition-colors duration-200 mt-4"
              >
                Request Demo
              </Link>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
}