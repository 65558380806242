import React from 'react';
import Layout from '../components/layout/layout';

export default function AboutUs({ initialData }) {
  return (
    <Layout initialData={initialData}>
      <div className="container py-12">
        <h1 className="text-4xl font-bold mb-6">About Us</h1>
        <p className="text-gray-600">
          Your about us content goes here...
        </p>
      </div>
    </Layout>
  );
}